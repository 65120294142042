import React from 'react'

import useAppStore from '../../../useAppStore';

const Experiments = ({ open, setOpen, title }) => {
    const { isViewOpen, viewTitle, closeView } = useAppStore(); // Access state and actions from the store

    function handleClose() {
        closeView(); // Call the closeModal action from the store
      }

  return (
<div>something about experiments can go here</div>
  );
}

export default Experiments
