
import create from 'zustand';
import zustymiddleware from 'zustymiddleware';

const useAppStore = create(
    zustymiddleware((set) => ({
        isViewOpen: false,
        viewTitle: '',
        openView: (title) => set((state) => ({ ...state, isViewOpen: true, viewTitle: title })),
        closeView: () => set((state) => ({ ...state, isViewOpen: false, viewTitle: '' })),
        setViewTitle: (title) => set((state) => ({ ...state, viewTitle: title })),
        message: '',
        setMessage: (msg) => set({ message: msg }),
         // New state for handling link/button clicks
        marqueeLinkClicked: '',
        setMarqueeLinkClicked: (linkName) => set({ marqueeLinkClicked: linkName }),
    }))
);

window.store = useAppStore;
export default useAppStore;
