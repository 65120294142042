import React from 'react'

import useAppStore from '../../../useAppStore';

const Contact = ({ open, setOpen, title }) => {
    const { isViewOpen, closeView } = useAppStore(); // Access state and actions from the store

    function handleClose() {
        closeView(); // Call the closeModal action from the store
      }

  return (
<div> Contact {title}</div>

    

 
  );
}

export default Contact
