import React, { useEffect, useRef } from 'react';
import './marqueeTextMenu.css';

const MarqueeTextMenu = ({ onClick, titleForLink, textFollowingTitle, speed }) => {

  const marqueeRef = useRef(null);
  useEffect(() => {
    const marquee = marqueeRef.current; // Access the marquee div
    marquee.innerHTML += marquee.innerHTML; // Duplicate the text for continuous scrolling

    let i = 0; // Initialize the starting position of the marquee
    const step = () => {
      i += speed; // Increment the position by the speed value
       if (i >= marquee.scrollWidth / 2) {
        i = 0; // Reset position when the text has fully scrolled
      } 
      marquee.style.transform = `translateX(-${i}px)`; // Move the text to the left
      requestAnimationFrame(step); // Schedule the next update before the next repaint
    };

    step(); // Start the animation

    // Cleanup function to stop the animation if the component unmounts
    return () => cancelAnimationFrame(step);
  }, [speed]); // Effect runs again if speed changes

  return (
    <div className='menuLinkWrapper'>
      <div onClick={() => onClick(titleForLink)} className='marqueeMenu' ref={marqueeRef}>{titleForLink}<span className='smallerText' >{textFollowingTitle}</span></div>
    </div>
  );
};

export default MarqueeTextMenu;