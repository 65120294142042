import React from 'react';
import Header from '../header/Header';
import Aside from '../aside/Aside';
import DiamondMenuColumn from '../diamondMenuColumn/DiamondMenuColumn';
//import MarqueeTextMenu from '../marqueeTextMenu/MarqueeTextMenu';
import MarqueeContainer from '../marqueeContainer/MarqueeContainer';
import Home from '../home/home';
import About from '../secondLevelComponents/about/About';
import Contact from '../secondLevelComponents/contact/Contact';
import Experiments from '../secondLevelComponents/experiments/Experiments';
import Projects from '../secondLevelComponents/projects/Projects';

import useAppStore from '../../useAppStore';

const Layout = ({ children, viewTitle, isViewOpen, open, setOpen, title}) => {
  const getViewTitle = useAppStore((state) => state.viewTitle);
  const getIsViewOpen = useAppStore((state) => state.isViewOpen);


  return (
    <div className={`grid grid-rows-5 h-screen layoutGrid ${getViewTitle === 'Home' || getViewTitle.length === 0 ? 'grid-cols-[20%_5%_19%_26%_30%]' : 'grid-cols-[20%_5%_.5%_26%_1fr]'} `}>     
      <div className="row-span-5">
          <Header />
      </div>
      <div className="disclaimer row-span-5">
         <Aside className='disclaimerContainer'/>
      </div>
      
      <div className={`row-span-5 diamondMenuContainer border-l border-r`} > 
         <DiamondMenuColumn className='diamondMenuContainer'/> 
      </div>
      <div className="row-span-5 border-r content-stretch">
         <MarqueeContainer/>
      </div>
      <div className="mainContent h-screen row-span-5"> 
      {(() => {
        switch (getViewTitle) {
        case 'Home':
          return <Home />
        case 'Projects':
          return <Projects />
        case 'Experiments':
          return <Experiments />
        case 'Contact':
          return <Contact />
        case 'About':
          return <About />
        default:
          return <Home />
       }
      })()}
      {/**  <Home /> toggling the property in app.js so i can change views here children*/}
      </div>
    </div>
  );
};

export default Layout;