import React, { useEffect } from 'react';
import './home.css';   
import gearImage from '../../assets/images/guiltyGear_resized.svg';
import useAppStore from '../../useAppStore';

const Home = () => {
/*   const glow = useAppStore((state) => state.glow);
  const setGlow = useAppStore((state) => state.setGlow); */
  const marqueeLinkClicked = useAppStore((state) => state.marqueeLinkClicked);
  const setMarqueeLinkClicked = useAppStore((state) => state.setMarqueeLinkClicked);

/*   useEffect(() => {
    if (marqueeLinkClicked === 'About') {
      setGlow(true);

      // Automatically turn off the glow after 3 seconds
      const timer = setTimeout(() =>  setGlow(false), 7000);
      return () => clearTimeout(timer);
    }
  }, [marqueeLinkClicked, setGlow]); */

  return (      
    <div className='homeContainer'>
      <div className='intro'>
      <div>
        {/* <div className={`IntroText ${glow ? 'glow' : ''}`}> */}
          I'm Luis Raffucci, a web developer from Houston, Texas, specializing in JavaScript, HTML, and CSS. Living in Texas but working with clients worldwide, I invite you to explore my projects. See where I've been and what I've done, and to get to know me.
        </div>
      </div>
      <div className='gearArea'>
        <img className="guiltyGear spin" srcSet={gearImage} />
      </div>
    </div>
  );
};

export default Home;
