import React from 'react';
import './header.css'
import { Link } from 'react-router-dom';
import useAppStore from '../../useAppStore.js'; // Import Zustand store


function Header() {
   // const viewTitle = useAppStore((state) => state.viewTitle);
   const viewTitle = useAppStore((state) => state.viewTitle ?? 'Home');
   const setViewTitle = useAppStore((state) => state.setViewTitle);
  
  
    const handleClick = (linkName) => {
        console.log(linkName);
        setViewTitle('Home');
      };

    return (
        <div className="titleContainer w-full">
            <Link onClick={handleClick}>
                    <span className="titleText">
                        <span className='titleText-L'>L</span>
                        <span className='titleText-R'>r</span>
                        <span className='titleText-4'>4</span>
                        <span className='titleText-0'>0</span>
                        <span className='titleText-4'>4</span>
                    </span>
            </Link>
            
        </div>
            );
};

export default Header;
