import React, { useState } from 'react';
import MarqueeTextMenu from '../marqueeTextMenu/MarqueeTextMenu.js';
import useAppStore from '../../useAppStore.js'; // Import Zustand store
import './marqueeContainer.css';


const MarqueeContainer = () => {
  const isViewOpen = useAppStore((state) => state.isViewOpen); 
  const openView = useAppStore((state) => state.openView); 
  const viewTitle = useAppStore((state) => state.viewTitle);
  const setViewTitle = useAppStore((state) => state.setViewTitle);

  const setMarqueeLinkClicked = useAppStore((state) => state.setMarqueeLinkClicked);

  const handleClick = (linkName) => {
    setMarqueeLinkClicked(linkName);
    setViewTitle(linkName);
    openView(linkName);
  };

/*   const aboutClick = (linkName) => {
    setMarqueeLinkClicked(linkName);
  }; */
  
  return (
    <div className='marqueeContainer'> 
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Home'} textFollowingTitle={'its the landing page!'} speed={1}/>
        <MarqueeTextMenu onClick={handleClick} titleForLink={'About'} textFollowingTitle={'who am i. what am i.'} speed={1}/>   
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Contact'} textFollowingTitle={'email maybe. send me a owl.'} speed={1}/>  
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Experiments'} textFollowingTitle={'projects that may be useful.'} speed={1}/>
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Projects'} textFollowingTitle={'projects that i have worked on.'} speed={1}/>
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Ideas'} textFollowingTitle={'maybe a living kanban (No Idea).'} speed={1}/>
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Longitude'} textFollowingTitle={'where am i, most of the time.'} speed={1}/>
        <MarqueeTextMenu onClick={handleClick} titleForLink={'Random'} textFollowingTitle={'i will take you to a random place.'} speed={1}/>   
        <div className='littleBitAtTheEndOfTheMenu' data-text="Ρεαχη φορ τηε Σκψ.">Ρεαχη φορ τηε Σκψ.</div>
    </div>
  );
};

export default MarqueeContainer;
