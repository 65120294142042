import React, { useEffect } from 'react'

import useAppStore from '../../../useAppStore';

const Projects = ({ open, setOpen, title }) => {
    console.log("inside experiments component");
    const {message ,setMessage, isModalOpen, closeModal } = useAppStore(); // Access state and actions from the store

    useEffect(() => {
      fetch('http://localhost:3810/api/hello.php')
      .then(response => response.json())
      .then(data => setMessage(data.message));
    }, []);
    
    function handleClose() {
        console.log("experimentsJS inside Handle Close");
        //setOpen(false);
        closeModal(); // Call the closeModal action from the store
      }

  return (
<div>getting your projects in a row.
{`${message}`}

</div>
  );
}

export default Projects
