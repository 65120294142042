import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import React, { useEffect, useState } from 'react';
import Layout from './components/layout/Layout';
import useAppStore from './useAppStore';

function App() {
  const { message ,setMessage, isViewOpen, openView, closeView, viewTitle } = useAppStore();

  useEffect(() => {
    fetch('http://localhost:3810/api/hello.php')
    .then(response => response.json())
    .then(data => setMessage(data.message));
  }, []);
  
  return (
      <Router>
        <Layout>
   
        </Layout>
      </Router>
  );
}

export default App;
