import React from 'react'

import useAppStore from '../../../useAppStore';

const About = ({ open, setOpen, title }) => {
    const { isViewOpen, closeView} = useAppStore(); // Access state and actions from the store

  return (
          <div className='text-base'>
            <p>
              Hello! I’m a software developer with over a decade of experience specializing 
              in front-end and backend technologies. Passionate about building efficient,
              user-centered applications, I work extensively with JavaScript, PHP, and 
              state management tools to create secure, scalable solutions that meet the 
              needs of modern users. My technical support experience includes 
              troubleshooting across Windows and Linux systems, managing user 
              directories, and refining workflows.
            </p>
          </div>
  );
}

export default About
